import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Skus from "../components/Products/RecordsLP";

const RecordsLP = () => (
  <Layout>
    <SEO
      keywords={[
        `punk records`,
        `punkrockvideos`,
        `punkrockvideos.com`,
        `punk`,
        `punkrock`,
        `records`,
        `poppunk`,
        `emo`,
        `garage rock`,
        `12 inchs`,
        `vinyl records`,
        `pop punk`,
        `out of print records`,
        `color vinyl`,
        `1970 punk`,
        "for sale",
        `Fat Records`,
        `Lookout Records`,
      ]}
      title="Records LPs"
    />
    <h1 className="mt-1 font-semibold text-lg leading-tight truncate">
      Records LPs
    </h1>
    <p>
      1990s 12 inch and 10 inch punk LP records. All records are out of print.
      All prices include shipping only US. If you have any questions contact me
      via the webform on the contact page.
    </p>
    <Skus />
  </Layout>
);

export default RecordsLP;
